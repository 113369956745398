<template>
  <div class="top-contributes-table">
    <SectionTitle title="推薦活動貢獻度排名 ( 前 10 名 )" fontSize="18" hideBtn />
    <el-table v-loading="loading" :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="會員姓名" prop="name" align="center" />
      <el-table-column label="電話號碼" prop="phone" align="center" />
      <el-table-column label="推薦連結點擊次數" prop="viewCount" align="center" />
      <el-table-column label="成功推薦次數" prop="successCount" align="center" />
      <el-table-column label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <router-link
            class="underline"
            :to="{
              name: 'MemberProfile',
              params: {
                id: scope.row.memberId
              },
              query: {
                openInviteRecord: true
              },
            }"
          >
            查看
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { GetMemberInviteReportRank } from '@/api/memberInvite'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'
import { map, get } from 'lodash'

export default defineComponent({
  name: 'TopContributesTable',
  components: { EmptyBlock },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { simpleFetch } = useFetch()

    const tableData = ref([])
    const loading = ref(false)

    const displayData = computed(() => {
      return map(tableData.value, (i) => {
        return {
          name: get(i, 'name'),
          phone: get(i, 'phone'),
          viewCount: get(i, 'viewCount'),
          successCount: get(i, 'successCount'),
          memberId: get(i, 'memberId'),
        }
      })
    })

    onMounted(async () => {
      loading.value = true
      await simpleFetch(GetMemberInviteReportRank, {
        shopId: shopId.value,
      }, res => {
        tableData.value = res
      })
      loading.value = false
    })

    return {
      loading,
      displayData,
    }
  },
})
</script>
