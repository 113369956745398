<template>
  <div class="member-invite-report">
    <PageTitle title="推薦會員報表" hideBtn />

    <div class="section-wrapper">
      <YearStatistic :yearMetrics="yearMetrics" />
      <TimeRangeStatistic :rangeMetrics="rangeMetrics" @refresh="getRangeMetrics" />
      <TopContributesTable />
      <InvitedMemberTable />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import YearStatistic from './components/YearStatistic.vue'
import TimeRangeStatistic from './components/TimeRangeStatistic.vue'
import TopContributesTable from './components/TopContributesTable.vue'
import InvitedMemberTable from './components/InvitedMemberTable.vue'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { useRangeTime } from '@/use/rangeTime'
import dayjs from 'dayjs'
import { GetMemberInviteReportMetrics } from '@/api/memberInvite'

export default defineComponent({
  name: 'MemberInviteReport',
  components: { PageTitle, YearStatistic, TimeRangeStatistic, TopContributesTable, InvitedMemberTable },
  setup (props) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const { getTodayRange } = useRangeTime()

    const yearMetrics = ref({})
    const rangeMetrics = ref({})

    const getRangeMetrics = async (e) => {
      const dates = e.date || e
      simpleFetch(GetMemberInviteReportMetrics, {
        shopId: shopId.value,
        start: dates[0],
        end: dates[1],
      }, res => {
        rangeMetrics.value = res
      })
    }

    onMounted(async () => {
      simpleFetch(GetMemberInviteReportMetrics, {
        shopId: shopId.value,
        start: dayjs().startOf('y').toDate(),
        end: dayjs().endOf('y').add(1, 's').toDate(),
      }, res => {
        yearMetrics.value = res
      })

      const todayDates = getTodayRange()
      getRangeMetrics(todayDates)
    })

    return {
      yearMetrics,
      rangeMetrics,
      getRangeMetrics,
    }
  },
})
</script>

<style lang="postcss" scoped>
.section-wrapper {
  @apply flex flex-col gap-[40px];
}
</style>
