var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top-contributes-table"},[_c('SectionTitle',{attrs:{"title":"推薦活動貢獻度排名 ( 前 10 名 )","fontSize":"18","hideBtn":""}}),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.displayData}},[_c('EmptyBlock',{attrs:{"slot":"empty"},slot:"empty"}),_c('el-table-column',{attrs:{"label":"會員姓名","prop":"name","align":"center"}}),_c('el-table-column',{attrs:{"label":"電話號碼","prop":"phone","align":"center"}}),_c('el-table-column',{attrs:{"label":"推薦連結點擊次數","prop":"viewCount","align":"center"}}),_c('el-table-column',{attrs:{"label":"成功推薦次數","prop":"successCount","align":"center"}}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"110","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"underline",attrs:{"to":{
            name: 'MemberProfile',
            params: {
              id: scope.row.memberId
            },
            query: {
              openInviteRecord: true
            },
          }}},[_vm._v(" 查看 ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }