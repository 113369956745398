<template>
  <div class="year-statistic">
    <SectionTitle fontSize="18" hideBtn>
      <template slot="title">
        <p style="font-size: 18px">年度統計</p>
        <p class="text-gray-60 text-sub" style="margin-left: 12px">計算範圍：當年度 1/1 00:00:00 ~ 隔年度 1/31 23:59:59</p>
      </template>
    </SectionTitle>
    <StatisticBlock :data="displayDataA" :prefix="null" />
  </div>
</template>

<script>
import StatisticBlock from '@/components/Report/StatisticBlock.vue'
import { computed, defineComponent } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'YearStatistic',
  components: { StatisticBlock },
  props: {
    yearMetrics: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const displayDataA = computed(() => {
      const data = props.yearMetrics
      return [
        { label: '推薦新增會員數', value: get(data, 'referralRecordCount') },
        { label: '總新增會員數（不分渠道）', value: get(data, 'memberCount') },
      ]
    })

    return { displayDataA }
  },
})

</script>

  <style scoped lang="postcss">

  </style>
