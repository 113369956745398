<template>
  <div class="year-statistic">
    <SectionTitle title="區間內統計" fontSize="18" hideBtn />
    <div class="record__datetime">
      <RangeTimeSelect
        @custom="refresh"
        @month="refresh"
        @week="refresh"
        @today="refresh"
      />
    </div>

    <StatisticBlock :data="displayData" :prefix="null" />
  </div>
</template>

<script>
import StatisticBlock from '@/components/Report/StatisticBlock.vue'
import RangeTimeSelect from '@/components/Report/RangeTimeSelect.vue'
import { computed, defineComponent } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'TimeRAngeStatistic',
  components: { StatisticBlock, RangeTimeSelect },
  props: {
    rangeMetrics: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const refresh = (e) => {
      emit('refresh', e)
    }

    const displayData = computed(() => {
      const data = props.rangeMetrics
      return [
        { label: '推薦新增會員數', value: get(data, 'referralRecordCount') },
        { label: '總新增會員數（不分渠道）', value: get(data, 'memberCount') },
      ]
    })

    return { displayData, refresh }
  },
})

</script>

<style scoped lang="postcss">

</style>
